<template>
  <div>
    <b-row class="pl-5 pr-5 mt-4">
      <b-col class="video-heatmap">
        <div :class="`video-container pt-4 ${optionBar === 'left' ? 'pl-4' : 'pr-4'
          }`">
          <video-selector-v2 style="max-width: 720px" v-if="isVideo" :activeVideo="this.getActiveVideo"
            :videosUrls="this.videos" :setTime="this.hoverTime" :isPlaying="this.videoIsPlaying"
            @videoTimeUpdate="videoTimeUpdate" @play="videoPlay" @pause="videoPause"></video-selector-v2>
          <image-selector v-if="isImage" :activeImage="this.getActiveImage" :imagesUrls="this.images">
          </image-selector>
        </div>

        <div :class="`toggle-container-${optionBar}`">
          <button v-if="videos.heatmap_path" class="no-decoration-btn toggle-btn" :class="{'active-button':activeVideo==='heatmap_path'}" @click="setVideo('heatmap_path')"><img src="../../../assets/chart-icons/heatmap-icon.svg" alt="Heat"></button>
          <button v-if="videos.fogmap_path" class="no-decoration-btn toggle-btn" :class="{'active-button':activeVideo==='fogmap_path'}" @click="setVideo('fogmap_path')"><img src="../../../assets/chart-icons/fogmap-icon.svg" alt="Fog"></button>
          <button v-if="videos.gazeplot_path" class="no-decoration-btn toggle-btn" :class="{'active-button':activeVideo==='gazeplot_path'}" @click="setVideo('gazeplot_path')"><img src="../../../assets/chart-icons/gaze-icon.svg" alt="gaze"> </button>
          <button v-if="videos.resource_path" class="no-decoration-btn toggle-btn" :class="{'active-button':activeVideo==='resource_path'}" @click="setVideo('resource_path')"><img src="../../../assets/viewer-icons/standard-viewer-mini-image.svg" alt="Norm"></button>
          <button class="mt-5 no-decoration-btn toggle-btn" @click="close">
            <b-icon icon="x"></b-icon>
          </button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="analytics p-4">
        <div class="mb-4" v-if="isVideo">
          <button variant="light" class="no-decoration-btn" @click="toggleShowGraph"
            style="color:var(--emotiva-pink)"
          >
            
            {{ !showGraph ? $t("button.showGraph") : $t("button.hideGraph") }}
          </button>
          <b-row class="graph p-4" v-if="showGraph">
            <b-col v-if="showGraph">
              <x-y-area-graph :xyData="this.xyData" :currentTime="this.currentTime"
                @updateCurrentTime="updateTimeFromGraph"></x-y-area-graph>
            </b-col>
          </b-row>
        </div>
        <div v-if="!isVideo && atLeastOneVideo" class="mb-4">
          &nbsp;
        </div>
        <heatmap-score-comparisson :saliencyScores="saliencyScores" :emotionScores="emotionScores"
          :benchmark="benchmark"></heatmap-score-comparisson>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import TitleInfo from "../components/Results/Score/TitleInfo.vue";
import VideoSelectorV2 from "../../../components/Results/VideoSelectorV2.vue";
import HeatmapScoreComparisson from "../../../components/Results/Heatmap/HeatmapScoreComparisson.vue";
import XYAreaGraph from "../../../components/Results/Score/XYAreaGraph.vue";
import ImageSelector from "../../../components/Results/ImageSelector.vue";
export default {
  components: {
    // TitleInfo,
    VideoSelectorV2,
    HeatmapScoreComparisson,
    XYAreaGraph,
    ImageSelector,
  },
  props: {
    analysis: {
      type: Object,
      required: true,
    },
    optionBar: {
      type: String,
      default: "left",
    },
    index: {
      type: Number,
      required: false,
      default: -1,
    },
    atLeastOneVideo:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      heatmapVisible: false,
      heatmapVisibleValue: 0,
      activeVideo: "heatmap_path",
      activeImage: "heatmap_path",
      videoIsPlaying: false,
      currentTime: 0,
      hoverTime: 0,
      showGraph: false,
    };
  },
  methods: {
    setVideo(video) {
      this.activeVideo = video;
      this.activeImage = video;
      if (this.isVideo) {
        this.hoverTime = this.currentTime;
      }
    },
    videoTimeUpdate(newTime, videoIndex) {
      this.currentTime = newTime;
    },
    updateTimeFromGraph(newTime) {
      this.hoverTime = newTime;
      this.videoPause();
    },
    videoPlay() {
      this.videoIsPlaying = true;
    },
    videoPause() {
      this.videoIsPlaying = false;
    },
    close() {
      const projectId = this.analysis.project._id;
      this.$emit("close", projectId);
    },
    toggleShowGraph() {
      this.showGraph = !this.showGraph;
    },
  },
  computed: {
    videos() {
      const ret = {
        fogmap_path: this.analysis.fogmap_path,
        gazeplot_path: this.analysis.gazeplot_path,
        heatmap_path: this.analysis.heatmap_path,
        resource_path: this.analysis.resource_path,
      };
      return ret;
    },
    images() {
      const ret = {
        fogmap_path: this.analysis.fogmap_path,
        gazeplot_path: this.analysis.gazeplot_path,
        heatmap_path: this.analysis.heatmap_path,
        resource_path: this.analysis.resource_path,
      };
      return ret;
    },
    getActiveVideo() {
      return this.activeVideo;
    },
    getActiveImage() {
      return this.activeImage;
    },
    saliencyScores() {
      // maybe change it later with mathfloor
      const ret = {
        attentionScore: parseInt(
          this.analysis.saliency_scores.attention_score.toFixed()
        ),
        focusScore: parseInt(
          this.analysis.saliency_scores.focus_score.toFixed()
        ),
        spreadScore: parseInt(
          this.analysis.saliency_scores.spread_score.toFixed()
        ),
        clarityScore: parseInt(
          this.analysis.saliency_scores.clarity_score.toFixed()
        ),
        mentalDemand: parseInt(
          this.analysis.saliency_scores.mental_demand.toFixed()
        ),
      };
      return ret;
    },
    emotionScores() {
      const ret = this.analysis.emotion_data;
      return ret;
    },
    benchmark() {
      const ret = {
        attentionScore: parseInt(
          this.analysis.benchmark?.attention_score.toFixed() ||0 
        ),
        focusScore:       parseInt(this.analysis.benchmark?.focus_score?.toFixed()||0 ),
        spreadScore:      parseInt(this.analysis.benchmark?.spread_score?.toFixed()||0 ),
        clarityScore:     parseInt(this.analysis.benchmark?.clarity_score?.toFixed()||0 ),
        mentalDemand:     parseInt(this.analysis.benchmark?.mental_demand?.toFixed()||0 ),
        engagement:       parseInt(this.analysis.benchmark?.engagement?.toFixed()||0 ),
        engagementScore:  parseInt(this.analysis.benchmark?.emotion_score?.toFixed()||0 )
      };
      return ret;
    },
    xyData() {
      return this.analysis.saliency_scores.video_data;
    },
    isVideo() {
      return this.analysis.data_type === "video";
    },
    isImage() {
      return this.analysis.data_type === "image";
    },
  },
};
</script>

<style scoped>
.analytics,
.graph {
  background-color: #fff;
}

.bold {
  font-weight: bold;
}

.toggle-container-left {
  position: absolute;
  margin: 10px;
  top: 0;
  left: -40px;
  width: auto;
  padding: 8px;
  height: auto;
  background-color: #fff;
  border-radius: 5px;
  /* align-items: center; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.toggle-container-right {
  position: absolute;
  margin: 10px;
  top: 0;
  right: -40px;
  width: auto;
  padding: 8px;
  height: auto;
  background-color: #fff;
  border-radius: 5px;
  /* align-items: center; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.graph {
  height: 600px;
}

.video-container {
  border-radius: 5px;
  min-width: 300px;
  max-width: 730px;
  position: relative;
}

.video-heatmap {
  display: flex;
  justify-content: center;
  position: relative;
  height: 504px;
}

.toggle-btn {
  background-color: tr;
  width: 44px;
  height: 44px;
  margin-top: 5px;
  background-color: #fff;
  border-radius: 5px;
}

.active-button {
  background-color: #edf0f7;
}
</style>
